import axios from 'axios';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}`,
  // baseURL: 'https://eventaddis.com/api',
  headers: {
    crossDomain: true,
    "Content-type": "application/json",
  },
  withCredentials: false,
});

const login = ( { email, password }: any) => {
  try {
    const response: any = apiClient.post('/login', { email, password });
    // localStorage.setItem('token', response.data.token);
    // console.log(JSON.parse(response))
    return response;
  } catch (error) {
    throw new Error();
  }
};

const AuthService = {
  login,
};

export default AuthService;
