// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

//
import App from './App';
import Login from './custom/pages/auth/Login';
import { QueryClient, QueryClientProvider } from 'react-query';


import { ReactQueryDevtools } from 'react-query/devtools';
import  { Toaster } from 'react-hot-toast';

// ----------------------------------------------------------------------
// Create a client
const queryClient = new QueryClient();
// ----------------------------------------------------------------------
const token = localStorage.getItem('token')

const Routing = () => {
  if(token){
    return (<>
    <HelmetProvider>
    <SettingsProvider>
      <CollapseDrawerProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CollapseDrawerProvider>
    </SettingsProvider>
  </HelmetProvider>,
    </>)
  }
  else{
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    );
  }

};


ReactDOM.render(
  <QueryClientProvider client={queryClient}>
  <Routing/>
  <Toaster />
  </QueryClientProvider>,
  document.getElementById('root')
);
