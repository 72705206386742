import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, Grid, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/Auth';
import toast from 'react-hot-toast';

const Login = () => {
  const navigate = useNavigate();
  const { mutateAsync, isLoading, isError } = useMutation(AuthService.login, {
    onSuccess: (response: any) => {
      console.log(response);
      toast.success('Login Successfull');
      localStorage.setItem('token', response.data.token);
      window.location.reload();
      navigate('/dashboard/claim');
    },
    onError: (error: any) => {
      console.log(error);
      toast.error(error.response.data.email, {
        style: {
          color: 'red',
        },
      });
      // toast.error('Invalid Credentials');
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
    mutateAsync(data);
  };

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Card sx={{ maxWidth: 500, mt: 30, backgroundColor: '#F5F5F5' }}>
        <CardActionArea>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                spacing={3}
                container
                direction={'row'}
                justifyContent="center"
                alignItems={'center'}
                sx={{ p: 2 }}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h3">Login</Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField label="Email" {...register('email', { required: true })} fullWidth />
                  <span style={{ color: 'red' }}>{errors.email && 'Email is required'}</span>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Password"
                    {...register('password', { required: true })}
                    type="password"
                    fullWidth
                  />
                  <span style={{ color: 'red' }}>{errors.password && 'Password is required'}</span>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button type="submit" variant="contained">
                    {' '}
                    {isLoading ? 'Loading...' : 'Submit'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default Login;
